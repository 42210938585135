<header>
  <nav class="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-nsp border-bottom box-shadow mb-3">
    <div class="container">
      <a [routerLink]="['/']">
        <img src="../../assets/BryceLogoV8.png" class="img-logo" alt="Bryce Resort" />
      </a>
      <a href="https://nspserves.org/about-the-organization/" class="rounded float-right">
        <img src="../../assets/NSPHeaderLogo-small.png" alt="NSP" [hidden]="isLogoHidden" />
      </a>
    </div>
  </nav>
  <ul class="nav nav-tabs justify-content-center">
    <li class="nav-item" *ngFor="let component of componentsMap | keyvalue">
      <a [class]="getActive(component.key)" (click)="navItemClick(component.key)">{{capitalizeFirstLetter(component.key)}}</a>
    </li>
  </ul>
</header>
