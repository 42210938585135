<div class="row justify-content-center">
  <span class="bryce-nsp-logo col-lg-6 col-sm-9 mb-sm-1"></span>
</div>
<div class="container" style="z-index:1;">
  <div class="container">
    <div class="row justify-content-center">
      <h1 class="display-3"><b>Bryce Mountain Patrol</b></h1>
      <div class="col-lg-6 col-sm-12 text-center">
        <p class="bg-light">
          <b>
            Bryce Mountain has proudly been served by members of the Bryce Mountain Patrol trained and certified
            by The National Ski Patrol to provide emergency first response services to skiers, bikers, and other
            mountain goers since 1968. We are currently looking for future patroller to carry on our tradition and join our ranks.
          </b>
        </p>
      </div>
    </div>
  </div>
</div>
